import React from 'react'
import About from 'components/About'

const AboutPage = () => {
  return (
    <>
      <About title="ABOUT" description="Junior Full-Stack Developer." />
    </>
  )
}

export default AboutPage
